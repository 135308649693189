.button-wrap {
  padding-bottom: 4rem;
  text-align: center;
}

.btn {
  letter-spacing: 0.15rem;
  border-radius: 2rem;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.333;
  padding: 1rem 3.5rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  border: 2px solid transparent;
  min-width: 20rem;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.btn-primary {
  background: #54ccf2;
  color: #fff;
  border-color: #54ccf2;
}

.btn-primary:active {
  background: #31b0d9;
  border-color: #31b0d9;
}

.btn-primary:hover {
  background: #6bdcff;
  border-color: #6bdcff;
}

.btn-primary:focus {
  background: #6bdcff;
  border-color: rgba(78, 171, 238, 0.8);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background: #f1faff;
  border-color: #b2b6bf;
  color: #8e979d;
  pointer-events: none;
}

.btn-default {
  background: #fff;
  color: #6c6c6c;
  border-color: #54ccf2;
}

.btn-default:active {
  background: #31b0d9;
  border-color: #31b0d9;
  color: #fff;
}

.btn-default:hover {
  background: #6bdcff;
  border-color: #6bdcff;
  color: #fff;
}

.btn-default:focus {
  background: #6bdcff;
  border-color: rgba(78, 171, 238, 0.8);
  color: #fff;
}

.btn-default.disabled,
.btn-default:disabled {
  background: #f1faff;
  border-color: #b2b6bf;
  color: #8e979d;
  pointer-events: none;
}

.btn-secondary {
  background: #5477f2;
  color: #fff;
  border-color: #5477f2;
}

.btn-secondary:active {
  background: #2c5afc;
  border-color: #2c5afc;
}

.btn-secondary:hover {
  background: #5464f2;
  border-color: #5464f2;
}

.btn-secondary:focus {
  background: #5464f2;
  border-color: rgba(78, 171, 238, 0.8);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background: #f1f3ff;
  border-color: #bcb2bf;
  color: #968e9d;
  pointer-events: none;
}




.btn svg {
  position: absolute;
  font-size: 2.7rem;
  right: 1.1rem;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
  .btn {
    font-size: 1.5rem;
    min-width: 24rem;
  }

  .btn svg {
    font-size: 3rem;
  }
}
