.venue-section {
  padding-bottom: 3rem;
}

.venue-map-holder {
  position: relative;
  height: 38rem;
  width: 100%;
  margin-bottom: 3rem;  
}

.venue-map-holder iframe {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0;
  width: 100%;
  height: 100%;
}

.venue-info {
  padding-bottom: 3rem;
  font-size: 1.8rem;
  line-height: 1.5;
}

.venue-info a {
  display: inline-block;
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  text-decoration: underline;
}

.venue-info a:hover {
  text-decoration: none;
}

.venue-info .event-date {
  display: block;
}

@media screen and (min-width: 640px) {
  .venue-holder {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    padding-bottom: 3rem;
  }

  .venue-location-holder {
    order: 0;
    flex: 1;
    padding-right: 3rem;
  }

  .venue-map-holder {
    width: 50%;
    height: 38rem;
  }
}

@media screen and (min-width: 768px) {
  .venue-holder {
    padding-bottom: 4.5rem;
  }
  
  .venue-map-holder {
    height: 69rem;
  }

  .venue-info {
    padding-bottom: 3rem;
    font-size: 2rem;
    line-height: 1.2;
  }
  
  .venue-info a {
    font-size: 2.4rem;
    line-height: 1.166;
  }
}