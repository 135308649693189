.page-title{
    font-weight: normal;
    display: inline-block;
}

.pass-title{
    font: 1.75rem Poppins, Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    margin-bottom: 1rem;
}

.performance-name{
    font: 1.5rem Poppins, Arial, Helvetica, sans-serif;
}

.btn-near{
    display: inline-block;
    padding: 0 0 0 3rem;
    list-style: none;
}

.btn-near li{
    display: inline-block;
    padding: 0;
}

.pass-infos p{
    margin-bottom: 1rem;
}

.pass-venue{
    color: #00d1ff;
    text-decoration: underline;
}

.pass-special{
    color: #E51A5E;
}

.pass-timetable{
    padding: 0;
    list-style: none;
}

.pass-timetable li{
    margin: 3rem 0 3rem 0;
    display: flex;
}

.pass-time{
    margin: 0;
    display: inline-block;
    width: 5rem;
    font: 1.5rem Poppins, Arial, Helvetica, sans-serif;
}

.pass-right-timetable{
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 5rem;
}

.pass-right-timetable p {
    margin-bottom: 1rem;
}

.performance-artists il {
    display: inline;
}

.performance-artist {
    color: #00d1ff;
    text-decoration: underline;
}

.btn-holder .btn-secondary {
    font-size: 1.1rem;
    line-height: 1;
    min-width: 0;
    padding: 0.6rem 1.9rem 0.6rem 1rem;
}

.btn-holder .btn-secondary svg {
    font-size: 1.8rem;
    right: 0.3rem;
}

@media screen and (min-width: 608px) {
    .pass-title{
        font-size: 2rem;
    }

    .performance-infos{
        text-align: justify;
    }

    @media screen and (min-width: 768px) {

        .pass-time{
            width: 10rem;
            font-size: 2rem;
        }

        .pass-title{
            font-size: 2.5rem;
        }

        .performance-name{
            font-size: 2rem;
        }
        
        @media screen and (min-width: 816px) {
            .pass-title{
                font-size: 3rem;
            }

            @media screen and (min-width: 1024px) {
                .programme-holder{
                    margin-left: 20rem;
                    margin-right: 20rem;
                }
            }
        }
    }
}
