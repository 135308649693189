.competition-info-wrap {
   padding-bottom: 2rem;
}

.competition-info-row {
   padding-bottom: 2rem;
}

.competition-info-col {
   padding-bottom: 3rem;
}

.competition-info-col .img-wrap {
   border-radius: 1rem 1rem 0 0;
}

.competition-info-col .img-wrap img {
   border-radius: 1rem 1rem 0 0;
   width: 100%;
}

.competition-info-body {
   background: #fff;
   color: #000;
   border-radius: 0 0 1rem 1rem;
   padding: 2rem;
}

.competition-info-col .artist-name {
   display: block;
   font-weight: 600;
   font-size: 2rem;
   line-height: 1.2;
   margin-bottom: 1rem;
}

@media screen and (min-width: 640px) {
   .competition-info-row {
      margin: 0 -1.5rem;
      padding-bottom: 3rem;
      display: flex;
      flex-wrap: wrap;
   }
   
   .competition-info-col {
      padding: 0 1.5rem 3rem;
      width: 50%;
      display: flex;
      flex-direction: column;
   }

   .competition-info-body {
      flex-grow: 1;
   }
}

@media screen and (min-width: 992px) {
   .competition-info-col {
      width: 33.33%;
   }
}

@media screen and (min-width: 1200px) {
   .competition-info-col {
      width: 25%;
   }
}