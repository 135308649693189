.modal-wrapper {
   width: 100%;
   height: 100vh;
   background: rgba(204, 204, 204, 0.5);
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
   padding: 1rem;
   opacity: 0;
   visibility: hidden;
   transition: all 0.3s ease-in-out;
   overflow-y: auto;
}

.modal-wrapper.active {
   opacity: 1;
   visibility: visible;
}

.modal-container {
   max-width: 55rem;
   background: #000;
   color: #fff;
   font-size: 1.2rem;
   line-height: 1.333;
   border-radius: 1rem;
   padding: 3.7rem 2rem;
   text-align: justify;
   position: relative;
   opacity: 0;
   visibility: hidden;
   transform: translateY(-10rem);
   transition: inherit;
}

.modal-wrapper.active .modal-container {
   visibility: visible;
   opacity: 1;
   transform: translateY(0);
}

.modal-title {
   display: block;
   font-size: 2.2rem;
   line-height: 1.181;
   font-weight: 600;
   margin-bottom: 2rem;
}

.modal-container p {
   margin-bottom: 1.5rem;
}

.modal-container ol {
   margin: 0;
   padding: 0 0 0 1rem;
}

.modal-container ol li {
   margin-bottom: 1rem;
}

.modal-container button {
   padding: 0;
   background: none;
   border: 0;
   cursor: pointer;
   position: absolute;
   right: 15px;
   top: 10px;
}


.modal-container svg {
   font-size: 3rem;
   line-height: 1;
   transition: all 0.3s ease-in-out;
   color: #fff;
}

.modal-container button:hover svg {
   color: #00d1ff;
}

@media screen and (min-width: 768px) {
   .modal-container {
      padding: 5rem 3rem;
   }
}

.modal-closer-outside {
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   width: 100%;
   height: 100vh;
   color: #000;
   border: 0;
   background: none;
   padding: 0;
   /* pointer-events: none; */
}