.dashboard-banner {
  background: url('../Images/dashboard-banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  min-height: 100vh;
  margin-bottom: 3rem;
}

.dashboard-banner:before {
  background: linear-gradient(180deg, transparent 37.6%, #000);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
}

/* main .inner-page-banner {
  display: none;
} */

.dashboard-banner.inner-page-banner {
  min-height: 49rem;
  margin-bottom: 5rem;
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: 'gogoia', Times New Roman, serif;
  justify-content: flex-end;
  padding: 12rem 2rem;
  position: relative;
  width: 100%;
  max-width: 122rem;
  margin: 0 auto;
  z-index: 2;
}

.dashboard-content span {
  display: block;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.dashboard-content span.top-text {
  font-size: 4rem;
  line-height: 1.225;
  margin-bottom: 1rem;
}
.dashboard-content span.meta-text {
  font-size: 3.6rem;
  line-height: 1.111;
}
.dashboard-content h1 {
  font-size: 9.6rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.promo-holder {
  margin-bottom: 4rem;
  position: relative;
}

.promo-link {
  position: relative;
  max-width: 90rem;
  margin: 0 auto;
  padding-top: 52.222%;
}

.promo-link iframe {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0;
  width: 100%;
  height: 100%;
}

.info-text-holder {
  padding: 0 2rem 1rem;
  position: relative;
}

.info-text-holder .info-text-block {
  font-size: 1.8rem;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 70rem;
  text-align: center;
}

.info-text-holder .info-text-block .button-wrap {
  padding-top: 3.5rem;
}

@media screen and (min-width: 430px) {
  .dashboard-content {
    padding-left: 4rem;
  }
}

@media screen and (min-width: 768px) {
  .dashboard-content {
    padding: 6rem 4rem;
    justify-content: center;
    align-items: center;
  }

  .dashboard-content h1 {
    font-size: 15rem;
  }

  .dashboard-content span.top-text {
    font-size: 5rem;
    line-height: 1.08;
  }

  .dashboard-content span.meta-text {
    font-size: 5rem;
    line-height: 1.08;
  }

  .info-text-holder .info-text-block {
    font-size: 2.4rem;
    line-height: 1.5;
  }
}

@media screen and (min-width: 1024px) {
  .dashboard-banner {
    margin-bottom: 0;
    min-height: 49rem;
  }

  .dashboard-banner.inner-page-banner {
    margin-bottom: 0;
  }

  .dashboard-content {
    padding: 12rem 4rem;
  }
  
  .dashboard-content h1 {
    font-size: 20rem;
  }

  .dashboard-content span.top-text {
    font-size: 6.4rem;
    line-height: 1.062;
  }

  .dashboard-content span.meta-text {
    font-size: 6.4rem;
    line-height: 1.062;
  }

  .promo-holder {
    margin-bottom: 6rem;
  }
  
  main .inner-page-banner {
    display: block;
  }
}
