.section-title-block {
  align-items: center;
  border-bottom: 0.1rem solid hsla(0, 0%, 100%, 0.5);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 3rem;
  padding-bottom: 0.5rem;
}

.section-title-block h2 {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
  width: 70%;
  text-transform: uppercase;
}

.section-title-block h2:only-child {
  width: 100%;
}

.section-title-block .link-wrap a {
  display: inline-block;
  padding: 0 2.5rem 0 0;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.285;
}

.section-title-block .link-wrap a svg {
  font-size: 2rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 430px) {
  .section-title-block h2 {
    font-size: 1.8rem;
    line-height: 1.5;
  }
}

@media screen and (min-width: 768px) {
  .section-title-block h2 {
    font-size: 2.2rem;
  }
}
