.header-holder {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 0;
  line-height: 0;
}

.header-holder + main {
  padding-top: 3rem;
}

.openModalBtn {
  font-size: 1.3rem;
  line-height: 1.23;
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  margin-left: auto;
  background: none;
  padding: 0;
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.openModalBtn:hover {
  color: #00d1ff;
}

.nav-opener {
  width: 2rem;
}

.nav-closer-outside {
  position: absolute;
  background-color: #00000000;
  color: #00000000;
  border: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}

@media screen and (min-width: 1024px) {
  .header-holder {
    background: #212427;
    box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.1);
    padding: 2.6rem 13rem;
    position: sticky;
    top:0;
    z-index: 10;
    margin-bottom: 5rem;
  }

  .header-holder + main {
    padding: 0;
  }

  .nav-opener,
  .nav-closer-outside {
    display: none;
  }

  .openModalBtn {
    position: absolute;
    right: 5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
    color: #cbcfd4;
  }
}

@media screen and (min-width: 1200px) {
  .openModalBtn  {
    font-size: 1.5rem;
  }
}