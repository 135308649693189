.margin {
    /* display: block; */
    /* margin-bottom: 4rem; */
    /* color: #00000000; */
    display: none;
}

/* @media screen and (min-width: 1024px) {
    .margin {
        display: none;
    }
} */