.btn-groups {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
}

.ticket-holder {
   padding-bottom: 4rem;
}

.ticket-text-content {
   font-size: 1.8rem;
   line-height: 1.5;
   padding: 2rem 0;
   text-align: center;
   max-width: 90rem;
   margin: 0 auto;
}

.ticket-text-content .img-wrap {
   margin: 0 0 3rem;
}

.ticket-text-content .img-wrap img {
   width: 100%;
}

.btn-groups li {
   margin: 0 0 3rem;
   padding: 0 1rem;
   text-align: center;
}

@media screen and (min-width: 768px) {
   .ticket-text-content {
      font-size: 2.4rem;
      line-height: 1.5;
   }

   .btn-groups li {
      margin-bottom: 3.5rem;
   }
}

@media screen and (min-width: 1024px) {
   .ticket-text-content .img-wrap {
      display: none;
   }
}
