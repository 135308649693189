.comingSoon-page-wrapper,
.error-page-wrapper {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
  z-index: 3;
  padding: 5rem 2rem;
}

.comingSoon-page-wrapper {
  z-index: 0;
}

.comingSoon-page-wrapper:before,
.error-page-wrapper:before {
  content: '';
  background: url('../Images/dashboard-banner.jpg');
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
}

.comingSoon-page-wrapper:after,
.error-page-wrapper:after {
  content: '';
  background: #000;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.3;
  z-index: 0;
}

.comingSoon-page-holder,
.error-page-holder {
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 60rem;
  font-size: 1.6rem;
  line-height: 1.5;
}

.comingSoon-page-holder h1,
.error-page-holder h1 {
  font-size: 9rem;
  line-height: 1;
  margin-bottom: 3rem;
  font-weight: 600;
}

.comingSoon-page-holder h1 {
  font-size: 6rem;
  text-transform: uppercase;
}

.error-page-holder .error-title {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  padding-bottom: 2rem;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
  justify-content: center;
}

.btn-group li {
  padding: 0 1.5rem;
  margin-bottom: 2rem;
}


@media screen and (min-width: 768px) {
  .error-page-holder h1 {
    font-size: 15rem;
    margin-bottom: 5rem;
  }

  .error-page-holder .error-title {
    padding-bottom: 3rem;
    font-size: 3rem;
    line-height: 1.133;
  }

  .comingSoon-page-holder h1 {
    font-size: 7rem;
  }
}
