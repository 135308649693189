.menu-btn {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  display: block;
  width: 3rem;
}

.menu-btn img {
  width: 100%;
}

.menu--close {
  margin-left: auto;
}

@media screen and (min-width: 1024px) {
  .menu-btn {
    display: none;
  }
}
